import { createStore } from "vuex";

const store = createStore({
  state: {
    currentYear: new Date().getFullYear(),
    adversariesWithCapabilityCategories: ['carbanak-fin7', 'wizard-spider-sandworm', 'triton'],
    capabilityRequirementTooltips: {
      linux: {
        default: 'This substep was executed in the Linux environment.',
        missing: 'This substep was executed in the Linux environment, where <participant_display_name> did not have an agent deployed.',
      },
      host: {
        default: 'This substep required the collection of Windows host-based data.',
        missing: 'This substep required the collection of Windows host-based data. <participant_display_name> did not leverage this type of capability.',
      },
      plc: {
        default: 'This substep required actively querying the programmable logic controllers.',
        missing: 'This substep required actively querying the programmable logic controllers. <participant_display_name> did not leverage this type of capability.',
      },
    },
    urlParams: null,
    participantSummary: {
      domain: '',
      allAdversariesInDomain: [],
      participant: {},
      participantOverview: {
        attackTreeSubsteps: {},
      }, 
      participantResults: {
        selectedAdversary: {
          display_name: "",
          domain: "",
          eval_round: null,
          name: "",
          participant_capabilities: [],
          status: ""
        },
        selectedScenario: { value: 1, displayText: 'Scenario 1', key: 'Scenario_1', disabled: false },
        defaultScenario: { value: 1, displayText: 'Scenario 1', key: 'Scenario_1', disabled: false },
      }
    },
    allAdversaries: {
      adversaries: []
    },
    allParticipants: {
      participants: []
    }
  },
  getters: {
    currentYear (state) {
      return state.currentYear;
    },
    adversariesWithCapabilityCategories (state) {
      return state.adversariesWithCapabilityCategories;
    },
    capabilityRequirementTooltips (state) {
      return state.capabilityRequirementTooltips;
    },
    selectedResultsAdversary (state) {
      return state.participantSummary.participantResults.selectedAdversary;
    },
    selectedResultsScenario (state) {
      return state.participantSummary.participantResults.selectedScenario;
    },
    summaryParticipant (state) {
      return state.participantSummary.participant;
    },
    summaryDomain (state) {
      return state.participantSummary.domain;
    },
    defaultResultsScenario (state) {
      return state.participantSummary.participantResults.defaultScenario;
    },
    allAdversariesInDomain (state) {
      return state.participantSummary.allAdversariesInDomain;
    },
    allAdversaries (state) {
      return state.allAdversaries.adversaries;
    },
    allParticipants (state) {
      return state.allParticipants.participants;
    }
  },
  // mutations modify the store synchronously
  mutations: {
    setParticipantData(state, participantData) {
      state.participantSummary.participant = participantData;
    },
    setParticipantSummaryDomain(state, domain) {
      state.participantSummary.domain = domain;
    },
    setAllAdversariesInDomain(state, data) {
      state.participantSummary.allAdversariesInDomain = data;
    },
    setAllAdversaries(state, data) {
      state.allAdversaries.adversaries = data;
    },
    setAllParticipants(state, data) {
      state.allParticipants.participants = data;
    },
    setURLParams(state, data) {
      // This isn't reactive for some reason, so access it directly each time with this.$store.state
      state.urlParams = data ? Object.fromEntries(data) : {};
    },
    setSelectedResultsAdversary(state, adversary) {
      state.participantSummary.participantResults.selectedAdversary = adversary;
    },
    setSelectedResultsScenario(state, scenarioObj) {
      state.participantSummary.participantResults.selectedScenario = scenarioObj;
    },
  },
  // actions modify the store asynchronously
  actions: {
  },
  modules: {
  }
})

export default store;