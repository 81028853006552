import { createApp } from "vue";
import App from "./App.vue";
import router,  { appUUID }  from "./router";
import axios from 'axios';
import store from './store'

// style imports
import '@mdi/font/css/materialdesignicons.css'
import './sass/main.scss';

// vuetify imports
import { createVuetify } from "vuetify/lib/framework.mjs";
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles';

// scrollspy import
import { registerScrollSpy } from 'vue3-scroll-spy'

const app = createApp(App)

// create vuetify
const vuetify = createVuetify({
  components,
  directives,
  display: {
    mobileBreakpoint: 'md',
    thresholds: {
      xs: 375,
      sm: 600,
      md: 912,
      lg: 1440,
      xl: 1920
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#6241c5',
          'primary-lighten': '#e8e1fc',
          background: '#ffffff',
          surface: '#f0f1f2',
          'surface-darken': "#d8d8d9",
          text: '#39434c',
          'surface-secondary': '#0e1d27',
          accent: '#0097A7'
        }
      }
    },
  },
});

// use vuetify, store, and scrollspy
app.use(vuetify).use(store).use(router)
registerScrollSpy(app)

// global variables
app.config.globalProperties.$api = axios.create({
  baseURL: '/api/'
});

app.config.globalProperties.$handleNotFound = function() {
  router.replace( { path: '/404', query: {} } );
};

app.config.globalProperties.$imageSrc = process.env.VUE_APP_IMAGE_SOURCE;
app.config.globalProperties.$mitreAttackExternalLink = process.env.VUE_APP_MITRE_ATTACK_EXTERNAL_LINK;
app.config.globalProperties.$appUUID = appUUID;
app.config.globalProperties.$layerSrc = process.env.VUE_APP_LAYER_SOURCE;
app.config.globalProperties.$navigatorExternalLink = process.env.VUE_APP_NAVIGATOR_EXTERNAL_LINK;

// global variables
const globalProperties = {
  $api: axios.create({ baseURL: '/api/' }),
  $handleNotFound: () => router.replace({ path: '/404', query: {} }),
  $imageSrc: process.env.VUE_APP_IMAGE_SOURCE,
  $mitreAttackExternalLink: process.env.VUE_APP_MITRE_ATTACK_EXTERNAL_LINK,
  $appUUID: appUUID,
  $layerSrc: process.env.VUE_APP_LAYER_SOURCE,
  $navigatorExternalLink: process.env.VUE_APP_NAVIGATOR_EXTERNAL_LINK,
};
app.provide('globalProperties', globalProperties);

// mount app
app.mount("#app");
