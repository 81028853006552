<template>
  <a
    :href="`/${getDomainURL(selectedEvaluationTab.domain)}/${selectedEvaluationTab.name}`"
    target=""
    rel="noopener"
    data-id="home.evaluationtabs.evaluationlogolink"
  >
    <v-img
      :src="`${globalProperties.$imageSrc}${selectedEvaluationTab.logo_image}`"
      max-width="200"
      width="100%"
      contain
    ></v-img>
  </a>
</template>
<script setup>
import { defineProps, inject } from 'vue';

const globalProperties = inject('globalProperties');

defineProps({
  selectedEvaluationTab: Object,
  getDomainURL: Function,
});
</script>