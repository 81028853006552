<template>
  <div v-if="showCard">
    <v-card
      v-if="display.smAndDown.value"
      color="primary"
    >
      <v-card-text class="mk-text--body text-left mt-2">
        <p>
          <b>Managed Services 3: Call for Participation</b>
        </p>
        <p>
          MITRE Engenuity is excited to open its Call for Participation for ATT&CK Evaluations Managed Services.
        </p>
        <p>
          This third round of Managed Services evaluations, designed for managed security service providers (MSSP) and
          managed detection and response (MDR) competencies, will focus on cloud-based attacks, response and containment
          strategies, and post-incident analysis.
        </p>
        <v-btn
          width=100%
          class="mt-4"
          color="background"
          @click="getEvaluatedExternal()"
        >
          Join MSR3
        </v-btn>
      </v-card-text>
    </v-card>
    <div v-else class="cfp-card">
      <div class="mb-4 cfp-btn-div">
        <v-btn
          color="background"
          icon="mdi-close"
          size="small"
          @click="closeCard()"
        />
      </div>
      <v-card
        color="background"
      >
        <v-card-title>
          <v-card
            color="background"
            border="sm"
            flat
          >
            <v-img
              class="ma-auto"
              :src="$imageSrc + adversary.logo_image"
              max-width="250"
              width="100%"
              contain
            />
            <div>
              <span class="mk-text--h2">{{ adversary.display_name }}</span>
              <span class="mk-text--h2">{{ ' ' + adversary.start_year }}</span>
            </div>
            <span class="c-text--primary mk-text--body">Call for Participation</span>
          </v-card>
        </v-card-title>
        <v-card-text class="mk-text--body text-left mt-4">
          <p>
            MITRE Engenuity is excited to open its Call for Participation for ATT&CK Evaluations Managed Services.
          </p>
          <p class="mt-4">
            This third round of Managed Services evaluations, designed for managed security service providers (MSSP) and
            managed detection and response (MDR) competencies, will focus on cloud-based attacks, response and containment
            strategies, and post-incident analysis.
          </p>
          <v-btn
            width=100%
            class="mt-4"
            color="primary"
            @click="getEvaluatedExternal()"
          >
            Join MSR3
          </v-btn>
          <v-btn
            width=100%
            class="mt-4"
            color="background"
            flat
            :to="`/managed-services/${adversary.name}/`"
          >
            Learn More
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useDisplay } from 'vuetify';

  const adversary = ref([]);
  const display = useDisplay();
  const showCard = ref(true);
  const store = useStore();

  const allAdversaries = computed(() => store.getters.allAdversaries);

  function closeCard() {
    showCard.value = !showCard.value;
  }

  function getEvaluatedExternal() {
    window.open("https://info.mitre-engenuity.org/msr3", "_blank", "noopener");
  }

  watch(allAdversaries, (newVal) => {
  if (newVal && Object.keys(newVal).length !== 0) {
    adversary.value = allAdversaries.value.find(adv => adv.status === "CALL_FOR_PARTICIPATION");
  }
}, { immediate: true });
</script>

<style lang="scss" scoped>
  @import "../../sass/pages/_cfp-alert.scss";
</style>