<template>
  <div>
    <v-app-bar color="background" class="nav-bar" id="header" width="100%" :height="$vuetify.display.mobile ? '60' : '80'">
      <v-app-bar-title class="mr-auto">
        <router-link to="/" :class="$vuetify.display.mobile ? 'router-link--mobile' : 'router-link'">
          <v-img
            :src="require(`../../../assets/images/MITRE_Engenuity_ATTACK-Evaluations_logo_PMS_purple.svg`)"
            width="296"
            height="32"
          />
        </router-link>
      </v-app-bar-title>
      <v-app-bar-nav-icon v-if="$vuetify.display.mobile" @click="drawer = true"></v-app-bar-nav-icon>
      <template v-for="nav_item in nav_items" :key="nav_item.title">
        <v-menu
          open-on-hover
          :offset="[15, 0]" 
          :key="nav_item.title"
          v-if="nav_item.dropdown_items && !nav_item.drawer_only && !$vuetify.display.mobile"
          :close-on-content-click="false"
          v-model="nav_item.menu"
        >
          <template v-slot:activator="{ props }" v-if="!$vuetify.display.mobile">
            <v-list-item-title
              v-bind="props"
              class="list__title nav-item"
              :class="{ 'nav-item--active': nav_item.menu }"
            >
              {{ nav_item.title }}
            </v-list-item-title>
          </template>

          <v-list flat class="list">
            <template v-for="dropdown_item in nav_item.dropdown_items" :key="dropdown_item.title">
              <div class="domain-section" v-if="nav_item.title === 'Results'">
                <div class="domain-title">{{dropdown_item.title}}</div>
                <v-list-item
                  v-for="subItem in dropdown_item.submenu_items"
                  :key="subItem.title"
                  :to="subItem.route"
                  exact
                  @click="nav_item.menu=false"
                  class="adversary-item"
                >
                    <v-list-item-title class="adversary-info">
                      {{ subItem.title }}
                      <span v-if="isNew(subItem.date_published)" class="new-label">NEW</span>
                      <span class="date-published">
                        {{ new Date(subItem.date_published).getFullYear() }}
                      </span>
                    </v-list-item-title>
                </v-list-item>
              </div>
              <v-list-group v-else-if="dropdown_item.submenu_items" :key="dropdown_item.title" color="primary">
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" :title="dropdown_item.title" exact>
                  </v-list-item>
                </template>
                <v-list-item
                  v-for="subItem in dropdown_item.submenu_items"
                  :key="subItem.title"
                  :to="subItem.route"
                  :title="subItem.title"
                  exact
                  @click="nav_item.menu=false"
                >
                </v-list-item>
              </v-list-group>
              <v-list-item
                v-else-if="dropdown_item.external"
                :key="dropdown_item.title"
                :href="dropdown_item.external"
                target="_blank"
                dense
                exact
                @click="nav_item.menu=false"
              >
                <v-list-item-title>
                  {{ dropdown_item.title }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-else
                :key="dropdown_item.title"
                :to="dropdown_item.route"
                :title="dropdown_item.title"
                @click="nav_item.menu=false"
                exact
              >
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-btn
          v-if="nav_item.external && !nav_item.drawer_only && !$vuetify.display.mobile"
          :href="nav_item.external"
          target="_blank"
          :key="nav_item.title"
          :class="nav_item.class"
          :data-id="nav_item.data_id ? nav_item.data_id : ''"
        >
          {{ nav_item.title }}
        </v-btn>

        <v-btn
          v-else-if="nav_item.route && !nav_item.drawer_only && !$vuetify.display.mobile"
          :to="nav_item.route"
          :key="nav_item.title"
          :class="nav_item.class"
          :data-id="nav_item.data_id ? nav_item.data_id : ''"
        >
          {{ nav_item.title }}
        </v-btn>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-if="$vuetify.display.mobile" v-model="drawer" color="background" location="right">
      <v-list nav flat>
        <template v-for="nav_item in nav_items">
          <template v-if="!nav_item.dropdown_items">
            <v-list-item
              v-if="nav_item.external"
              :key="nav_item.title"
              :href="nav_item.external"
              :title="nav_item.title"
              exact
            >
            </v-list-item>
            <v-list-item
              v-else
              :key="nav_item.title"
              :to="nav_item.route"
              link
              :title="nav_item.title"
              exact
            >
            </v-list-item>
          </template>
          <v-list-group v-else :key="nav_item.title" :title="nav_item.title" color="primary">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" class="text-wrap" :title="nav_item.title"></v-list-item>
            </template>
            <template v-for="dropdown_item in nav_item.dropdown_items" :key="dropdown_item.title">
              <v-list-group v-if="dropdown_item.submenu_items" :title="dropdown_item.title" color="primary">
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" class="text-wrap" :title="dropdown_item.title" exact></v-list-item>
                </template>
                <template v-for="submenu_item in dropdown_item.submenu_items" :key="submenu_item.title">
                  <v-list-item :title="submenu_item.title" class="pl-0" link :to="submenu_item.route" exact>
                  </v-list-item>
                </template>
              </v-list-group>
              <v-list-item
                v-else-if="dropdown_item.external"
                :key="dropdown_item.title"
                :href="dropdown_item.external"
                target="_blank"
                exact
                class="list__item"
              >
                <v-list-item-title>
                  {{dropdown_item.title}}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-else
                :key="dropdown_item.title"
                :to="dropdown_item.route"
                link
                :title="dropdown_item.title"
                exact
              >
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { mapGetters } from 'vuex';
import { getURLFromDomain } from '../../mixins';
 
export default {
  name: 'Navigation',
  data: () => ({
    drawer: false,
    group: null,
    route: useRoute(),
    nav_items: [
      {
        title: 'Home',
        route: '/',
        icon: 'mdi-home',
        drawer_only: true,
        menu: false,
      },
      {
        title: 'Results',
        icon: 'mdi-domain',
        drawer_only: false,
        menu: false,
        class: 'nav-item--button',
        dropdown_items: [],
      },
      {
        title: 'About',
        icon: 'mdi-information',
        drawer_only: false,
        menu: false,
        dropdown_items: [
          { title: 'Evaluation', route: '/about/' },
          { title: 'Methodology', route: '/methodology-overview/' },
          { title: 'FAQ', route: '/FAQ/' },
          { title: 'Blog', external: 'https://medium.com/mitre-engenuity/tagged/evaluation' },
        ],
      },
      {
        title: 'Resources',
        icon: 'mdi-folder',
        drawer_only: false,
        menu: false,
        dropdown_items: [
          { title: 'Emulation Library', external: 'https://github.com/attackevals/ael' },
          { title: 'MITRE ATT&CK Defender Training', external: 'https://mitre-engenuity.org/cybersecurity/#' },
          { title: 'Center for Threat-Informed Defense', external: 'https://mitre-engenuity.org/cybersecurity/center-for-threat-informed-defense/' },
        ],
      },
      {
        title: 'Get Updates',
        route: '/get-updates/',
        icon: 'mdi-clipboard-text',
        drawer_only: false,
        menu: false,
        class: ['mk-button--primary-ghost', 'ml-7'],
        data_id: 'mainnav.getupdates.button'
      },
      {
        title: 'Get Evaluated',
        external: 'https://mitre-engenuity.org/cybersecurity/attack-evaluations/#get-evaluated',
        icon: 'mdi-clipboard-text',
        drawer_only: false,
        menu: false,
        class: ['mk-button--primary', 'ml-7'],
        data_id: 'mainnav.getevaluated.button'
      },
      {
        title: 'Contact Us',
        route: '/contact-us/',
        icon: 'mdi-card-account-mail',
        drawer_only: true,
        menu: false,
      },
      {
        title: 'Terms of Use',
        route: '/terms-of-use/',
        icon: 'mdi-clipboard-edit',
        drawer_only: true,
        menu: false,
      },
      {
        title: 'Privacy Policy',
        route: '/privacy-policy/',
        icon: 'mdi-file-lock',
        drawer_only: true,
        menu: false,
      },
    ],
  }),
  computed: {
    ...mapGetters(['allAdversaries']),
  },
  watch: {
  allAdversaries: {
    handler(allAdversaries) {
      if (allAdversaries && Object.keys(allAdversaries).length !== 0) {
        const groupedAdversaries = allAdversaries.reduce((acc, adv) => {
          if (adv.status === "PUBLISHED") {
            if (!acc[adv.domain]) {
              acc[adv.domain] = [];
            }
            acc[adv.domain].push(adv);
          }
          return acc;
        }, {});

        for (const domain in groupedAdversaries) {
          groupedAdversaries[domain].sort((a, b) => b.eval_round - a.eval_round);
        }

        const resultsDropdown = this.nav_items.find(item => item.title === 'Results');
        resultsDropdown.dropdown_items = Object.keys(groupedAdversaries).map(domain => {
          const domainInfo = getURLFromDomain(domain);
          return {
            title: domainInfo ? domainInfo.domain_label : domain,
            submenu_items: groupedAdversaries[domain].map(adv => ({
              title: adv.display_name,
              date_published: adv.date_published,
              route: `/results/${domainInfo.urlDomain}/?evaluation=${adv.name}&scenario=1&view=individualParticipant`,
            })),
          };
        });
      }
    },
    immediate: true,
  },
},

  methods: {
    isNew(datePublished) { //new if published within the past 3 months
      const publishedDate = new Date(datePublished);
      const currentDate = new Date();
      const diffMonths = (currentDate.getFullYear() - publishedDate.getFullYear()) * 12 + (currentDate.getMonth() - publishedDate.getMonth());
      return diffMonths <= 3;
    },
  },
};
</script>


<style scoped lang="scss">
  @import "../../sass/pages/_navigation.scss";
</style>