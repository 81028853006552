<template>
  <v-row justify="center" align="center">
    <v-col
      :class="{
        'col--more-x-padding': true,
        'mk-container--surface-secondary': theme === 'dark',
      }"
    >
      <v-card
        :color="theme === 'dark' ? 'surface-secondary' : 'white'"
        elevation="0"
        class="px-2 py-5"
      >
        <div
          :class="{
            'mk-text--h2 m-spacing--top-5 mk-text--semibold text-center': true,
            'c-text--white': theme === 'dark',
          }"
        >
          {{ `Our ATT&CK® Evaluations methodology` }}
        </div>
        <div
          :class="{
            'mk-text--body m-spacing--top-6 mk-text--regular text-left': true,
            'c-text--white': theme === 'dark',
          }"
        >
          ATT&CK® Evaluations' mission is to bridge the gap between the security solution providers
          and their users/customers by enabling users to better understand and defend against known
          adversary behaviors through a transparent evaluation process and publicly available
          results - leading to a more informed community and safer world for all. We use adversary
          emulation to scope evaluations in context of the MITRE ATT&CK® framework. The evaluations
          address today's threats by using tactics, tools, methods, and goals inspired by that of
          known attacks.
        </div>
        <div
          :class="{
            'mk-text--body m-spacing--top-6 mk-text--regular text-left': true,
            'c-text--white': theme === 'dark',
          }"
        >
          Techniques are executed in a logical step-by-step ordering to explore the breadth of
          ATT&CK coverage. And because adversaries may execute the same technique, but in very
          different ways, our evaluations use procedural variation to capture the same behavior via
          different methods to explore the depth of ATT&CK coverage.
        </div>
        <v-expansion-panels class="m-spacing--top-6" v-model="panel">
          <v-divider
            :class="{
              'm-spacing--top-5 divider': true,
              'c-text--white': theme === 'dark',
              'c-text--black': theme === 'light',
            }"
          ></v-divider>
          <v-expansion-panel
            :bg-color="theme === 'dark' ? 'surface-secondary' : 'white'"
            class="expansion-panel"
          >
            <v-expansion-panel-title class="mk-text--h3">
              <template v-slot:actions="{ expanded }">
                <v-icon
                  color="primary"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  class="expand-icon"
                ></v-icon>
                <span class="mt-1 ml-2">Develop</span>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row class="ml-6">
                <v-col class="d-flex" :cols="getCols(2)">
                  <v-row
                    :class="{
                      'expansion-panel-box  my-1 mr-2': true,
                      'c-col--border-white': theme === 'dark',
                      'c-col--border-black': theme === 'light',
                    }"
                  >
                    <span class="material-symbols-rounded mt-2"> edit_calendar </span>
                    <div class="mk-text--h5">Planning</div>
                    <div
                      :class="{
                        'mk-text--grey40': theme === 'dark',
                        'mk-text--grey80': theme === 'light',
                      }"
                    >
                      Threat landscape research and adversary selection
                    </div>
                    <v-divider
                      :class="{
                        'm-spacing--top-5 divider': true,
                        'c-text--white': theme === 'dark',
                        'c-text--black': theme === 'light',
                      }"
                    ></v-divider>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Intent</div>
                    <div class="text-left mk-text--regular">
                      We take into account concerns (e.g ransomeware vs. data theft) voiced from our
                      user community
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Differentiation</div>
                    <div class="text-left mk-text--regular">
                      We balance the usage of new and previously tested techniques
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Sophistication</div>
                    <div class="text-left mk-text--regular">
                      We consider development resources and whether we are baselining or pushing
                      defenses
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Intelligence</div>
                    <div class="text-left mk-text--regular">
                      We assess the quantity and quality of intel to thoroughly understand the
                      adversary
                    </div>
                  </v-row>
                </v-col>
                <v-col class="d-flex" :cols="getCols(2)">
                  <v-row
                    :class="{
                      'expansion-panel-box  my-1 mr-2': true,
                      'c-col--border-white': theme === 'dark',
                      'c-col--border-black': theme === 'light',
                    }"
                  >
                    <span class="material-symbols-rounded"> code </span>
                    <div class="mk-text--h5">Development</div>
                    <div
                      :class="{
                        'mk-text--grey40': theme === 'dark',
                        'mk-text--grey80': theme === 'light',
                      }"
                    >
                      Development of the components required to conduct the evaluation
                    </div>
                    <v-divider
                      :class="{
                        'm-spacing--top-5 divider': true,
                        'c-text--white': theme === 'dark',
                        'c-text--black': theme === 'light',
                      }"
                    ></v-divider>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Decomposition</div>
                    <div class="text-left mk-text--regular">
                      We extract cyber threat intelligence (CTI) into individual components that
                      compromise the emulation plan
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Chain</div>
                    <div class="text-left mk-text--regular">
                      We recompile and organize procedures into a larger emulation scenario
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Refinement</div>
                    <div class="text-left mk-text--regular">
                      We fill in gaps through collaboration and targeted research
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Tooling</div>
                    <div class="text-left mk-text--regular">
                      We select/build offensive tools that can faithfully replicate behaviors
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Customization</div>
                    <div class="text-left mk-text--regular">
                      We capture important tradecraft details (e.g. delivery mechanisms, command and
                      control, etc.)
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Review</div>
                    <div class="text-left mk-text--regular">We compare against CTI and note deviations</div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Creation</div>
                    <div class="text-left mk-text--regular">
                      We compile all the information into a structured emulation plan
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-divider
            :class="{
              divider: true,
              'c-text--white': theme === 'dark',
              'c-text--black': theme === 'light',
            }"
          ></v-divider>
          <v-expansion-panel
            :bg-color="theme === 'dark' ? 'surface-secondary' : 'white'"
            class="expansion-panel"
          >
            <v-expansion-panel-title class="mk-text--h3">
              <template v-slot:actions="{ expanded }">
                <v-icon
                  color="primary"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  class="expand-icon"
                ></v-icon>
                <span class="mt-1 ml-2">Execute</span>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row class="ml-6">
                <v-col :cols="getCols(3)" class="d-flex">
                  <v-row
                    :class="{
                      'expansion-panel-box  my-1 mr-2': true,
                      'c-col--border-white': theme === 'dark',
                      'c-col--border-black': theme === 'light',
                    }"
                  >
                    <span class="material-symbols-rounded"> groups </span>
                    <div class="mk-text--h5">Orientation</div>
                    <div
                      :class="{
                        'mk-text--grey40': theme === 'dark',
                        'mk-text--grey80': theme === 'light',
                      }"
                    >
                      On-boarding and preparedness for the evaluation execution
                    </div>
                    <v-divider
                      :class="{
                        'm-spacing--top-5 divider': true,
                        'c-text--white': theme === 'dark',
                        'c-text--black': theme === 'light',
                      }"
                    ></v-divider>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Environment</div>
                    <div class="text-left mk-text--regular">
                      We provide all participants identical ranges to start
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Collaboration</div>
                    <div class="text-left mk-text--regular">
                      MITRE Engenuity collaborates with participants to prepare them for success
                      during the evaluation
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">
                      Orientation Sessions
                    </div>
                    <div class="text-left mk-text--regular">
                      Orientation sessions are held to provide guidance to the process of
                      evaluations
                    </div>
                  </v-row>
                </v-col>
                <v-col :cols="getCols(3)" class="d-flex">
                  <v-row
                    :class="{
                      'expansion-panel-box  my-1 mr-2': true,
                      'c-col--border-white': theme === 'dark',
                      'c-col--border-black': theme === 'light',
                    }"
                  >
                    <span class="material-symbols-rounded"> settings </span>
                    <div class="mk-text--h5">Setup</div>
                    <div
                      :class="{
                        'mk-text--grey40': theme === 'dark',
                        'mk-text--grey80': theme === 'light',
                      }"
                    >
                      Deploying and installing tools into the range
                    </div>
                    <v-divider
                      :class="{
                        'm-spacing--top-5 divider': true,
                        'c-text--white': theme === 'dark',
                        'c-text--black': theme === 'light',
                      }"
                    ></v-divider>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Solution</div>
                    <div class="text-left mk-text--regular">
                      The participant configures their commercially available solution(s) with a
                      realistic configuration
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Configuration</div>
                    <div class="text-left mk-text--regular">
                      The participant ensures that preventions, protections, and responses are
                      alert-only for the detection evaluation and automated for the protection
                      evaluation
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Finalization</div>
                    <div class="text-left mk-text--regular">
                      Checks are performed at this time to ensure your tools will not interfere with
                      execution and are compliant with the guidelines
                    </div>
                  </v-row>
                </v-col>
                <v-col :cols="getCols(3)" class="d-flex">
                  <v-row
                    :class="{
                      'expansion-panel-box  my-1 mr-2': true,
                      'c-col--border-white': theme === 'dark',
                      'c-col--border-black': theme === 'light',
                    }"
                  >
                    <span class="material-symbols-rounded"> tactic </span>
                    <div class="mk-text--h5">Execution</div>
                    <div
                      :class="{
                        'mk-text--grey40': theme === 'dark',
                        'mk-text--grey80': theme === 'light',
                      }"
                    >
                      Execution of the evaluation
                    </div>
                    <v-divider
                      :class="{
                        'm-spacing--top-5 divider': true,
                        'c-text--white': theme === 'dark',
                        'c-text--black': theme === 'light',
                      }"
                    ></v-divider>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Evaluator</div>
                    <div class="text-left mk-text--regular">
                      We are the adversary, the proctor, as well as offer detection guidance to
                      assist the participant
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Defender</div>
                    <div class="text-left mk-text--regular">
                      The participant is the defender in an open-book purple team evaluation session
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-divider
            :class="{
              divider: true,
              'c-text--white': theme === 'dark',
              'c-text--black': theme === 'light',
            }"
          ></v-divider>
          <v-expansion-panel
            :bg-color="theme === 'dark' ? 'surface-secondary' : 'white'"
            class="expansion-panel"
          >
            <v-expansion-panel-title class="mk-text--h3">
              <template v-slot:actions="{ expanded }">
                <v-icon
                  color="primary"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  class="expand-icon"
                ></v-icon>
                <span class="mt-1 ml-2">Publish</span>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row class="ml-6">
                <v-col :cols="getCols(2)" class="d-flex">
                  <v-row
                    :class="{
                      'expansion-panel-box  my-1 mr-2': true,
                      'c-col--border-white': theme === 'dark',
                      'c-col--border-black': theme === 'light',
                    }"
                  >
                    <span class="material-symbols-rounded"> forum </span>
                    <div class="mk-text--h5">Processing and Feedback</div>
                    <div
                      :class="{
                        'mk-text--grey40': theme === 'dark',
                        'mk-text--grey80': theme === 'light',
                      }"
                    >
                      Results processing and calibration
                    </div>
                    <v-divider
                      :class="{
                        'm-spacing--top-5 divider': true,
                        'c-text--white': theme === 'dark',
                        'c-text--black': theme === 'light',
                      }"
                    ></v-divider>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Uniqueness</div>
                    <div class="text-left mk-text--regular">Participants are considered independently</div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Consistency</div>
                    <div class="text-left mk-text--regular">
                      Results are calibrated across steps/sub-steps of the emulation plan to ensure
                      consistency
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Review</div>
                    <div class="text-left mk-text--regular">
                      Participants are allowed time to provide feedback, which can include
                      additional data to be considered for inclusion, or modifications to our
                      preliminary results
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Analysis</div>
                    <div class="text-left mk-text--regular">
                      Feedback is considered and then a final determination is made
                    </div>
                  </v-row>
                </v-col>
                <v-col :cols="getCols(2)" class="d-flex">
                  <v-row
                    :class="{
                      'expansion-panel-box my-1 mr-2': true,
                      'c-col--border-white': theme === 'dark',
                      'c-col--border-black': theme === 'light',
                    }"
                  >
                    <span class="material-symbols-rounded"> receipt_long </span>
                    <div class="mk-text--h5">Publication</div>
                    <div
                      :class="{
                        'mk-text--grey40': theme === 'dark',
                        'mk-text--grey80': theme === 'light',
                      }"
                    >
                      Publishing the results
                    </div>
                    <v-divider
                      :class="{
                        'm-spacing--top-5 divider': true,
                        'c-text--white': theme === 'dark',
                        'c-text--black': theme === 'light',
                      }"
                    ></v-divider>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Preview</div>
                    <div class="text-left mk-text--regular">
                      Participants are provided a final review for sensitivity concerns (e.g., rule
                      logic)
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Guidelines</div>
                    <div class="text-left mk-text--regular">
                      Marketing guidelines and review protocol are shared to ensure guidelines are
                      met before all content is published
                    </div>
                    <div class="m-spacing--top-5 mk-text--semibold text-left">Publication</div>
                    <div class="text-left mk-text--regular">We publicly release results and the methodology</div>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { useDisplay } from "vuetify";

const panel = ref([props.default]);

const props = defineProps({
  default: Number,
  theme: String
})

function getCols(numCols) {
  const { xl, lg, md, sm } = useDisplay();
  if (numCols === 2) {
    return xl.value ? 6 : lg.value ? 6 : md.value ? 12 : sm.value ? 12 : 'auto';
  } else return xl.value ? 4 : lg.value ? 4 : md.value ? 12 : sm.value ? 12 : 'auto'
}
</script>

<style lang="scss">
@import '../sass/pages/_methodology.scss';
</style>
