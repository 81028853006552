<template>
  <v-container v-if="crumbs.length" fluid :class="{ 'pl-7 mt-4': !$vuetify.display.mobile, 'pt-4' : true }">
    <v-row dense>
      <v-col class="text-left">
        <v-breadcrumbs :items="crumbs" divider=">" align="left"></v-breadcrumbs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useRoute } from 'vue-router';
import { mapGetters } from 'vuex';
import { getDomainFromURL } from '../../mixins';

export default {
  data(){
    return{
      route: useRoute(),
    }
  },
  computed: {
    ...mapGetters(['allAdversaries', 'allParticipants']),
    crumbs: function () {
      let pathArray = this.route.path.split('/');
      pathArray = pathArray.filter((item) => item);
      let breadcrumbs = pathArray.reduce((crumbArray, path, index) => {
        let crumb_text;
        try {
          crumb_text = this.route.matched[index].meta.breadcrumb;
        } catch (e) {
          crumbArray[index - 1].text = crumbArray[index - 1].text + ' - ' + path;
          return crumbArray;
        }
        if (crumb_text.includes(':')) {
          crumb_text = crumb_text.replace(/:.[^ ]*/g, path);
          crumb_text = this.formatParameters(crumb_text.toLowerCase());
        }
        let to_path;
        to_path = this.route.matched[index] ? this.route.matched[index].path : '/' + path;
        if (to_path.includes(':')) {
          let path_params = to_path.match(/:.[^/]*/g);
          for (let p of path_params) {
            let this_param = this.route.params[p.replace(':', '')];
            to_path = to_path.replace(p, this_param);
          }
        }
        crumbArray.push({
          path: path,
          to: to_path,
          title: crumb_text,
          disabled: false,
          exact: true,
        });
        return crumbArray;
      }, []);
      breadcrumbs = breadcrumbs.filter((crumb) => crumb);
      if (pathArray.length > 0) {
        breadcrumbs.unshift({
          path: '/',
          to: '/',
          title: 'Home',
        });
        breadcrumbs[breadcrumbs.length - 1].disabled = true;
      }
      return breadcrumbs;
    },
  },
  methods: {
    formatParameters(inputStr) {
      let outputStr = inputStr;
      let domainMap = getDomainFromURL(inputStr);
      if (typeof domainMap !== 'undefined') {
        outputStr = outputStr = domainMap.domain_label;
      }
      else {
        var punctuation = '[.,/#!$%^&*;:{}=_`~()]';
        var re = new RegExp(punctuation, 'g');
        outputStr = outputStr.replace(re, ' ');
        const participantNames = this.allParticipants.map((participant) => participant.name);
        const filteredParticipantNames = participantNames.filter((name) => outputStr.includes(name));
        if (filteredParticipantNames.length > 0) {
          let thisParticipant = this.allParticipants.filter((participant) =>
            participant.name.includes(filteredParticipantNames[0]),
          );
          outputStr = outputStr.replace(filteredParticipantNames[0], thisParticipant[0].display_name);
        }
        const adversaryNames = this.allAdversaries.map((adversary) => adversary.name);
        const filteredAdversaryNames = adversaryNames.filter((name) => outputStr.includes(name));
        if (filteredAdversaryNames.length > 0) {
          let thisAdversary = this.allAdversaries.filter((adversary) =>
            adversary.name.includes(filteredAdversaryNames[0]),
          );
          outputStr = outputStr.replace(filteredAdversaryNames[0], thisAdversary[0].display_name);
        }
      }
      return outputStr;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../../sass/pages/_bread-crumb.scss";
</style>
